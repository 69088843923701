<template>
  <!--/inner-page-->
  <div class="inner-banner inner-banner-produtos py-5">
    <section class="w3l-breadcrumb text-left py-sm-5">
      <div class="container">
        <div class="w3breadcrumb-gids">
          <div class="w3breadcrumb-left text-left">
            <h2 class="inner-w3-title"><br /></h2>
          </div>
          <div class="w3breadcrumb-right">
            <!-- <ul class="breadcrumbs-custom-path">
              <li><a href="index.html">Home</a></li>
              <li class="active">
                <span class="fas fa-angle-double-right mx-2"></span> Produtos
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--//inner-page-->
  <div style="margin: 8px auto; display: block; text-align: center">
    <!---728x90--->
  </div>

  <!--/w3l-ecommerce-main-->
  <section class="w3l-ecommerce-main">
    <!-- /products-->
    <div class="ecom-contenthny w3l-ecommerce-main-inn py-5">
      <div class="container py-lg-5">
        <div class="ecommerce-grids row">
          <div class="ecommerce-left-hny col-lg-4">
            <!--/ecommerce-left-hny-->
            <aside class="pe-lg-4">
              <div class="sider-bar">
                <!-- /Gallery-imgs -->

                <div class="single-gd mb-5">
                  <h4>Veiculos</h4>
                  <ul class="list-group single"
                    style="height: 670px; overflow: scroll;">
                    <template v-for="veiculo_resumo in produtosResumo" :key="veiculo_resumo.veiculo">
                      <li
                        class="list-group-item d-flex justify-content-between align-items-center"
                      >
                      <a :href="`/produtos?search=${search_.qs.replace(veiculo_resumo.veiculo, '') +' '+veiculo_resumo.veiculo}`">
                        {{ veiculo_resumo.veiculo }}
                      </a>
                        <span class="badge badge-primary badge-pill">{{ veiculo_resumo.veiculo_count }}</span>
                      </li>  
                    </template>
                  </ul>
                </div>
              </div>
            </aside>
            <!--//ecommerce-left-hny-->
          </div>
          <!-- /row-->
          <div v-if="produtos" class="ecommerce-right-hny col-lg-8">
            <div class="row ecomhny-topbar">
              <div class="col-6 ecomhny-result">
                <h4 class="ecomhny-result-count">
                  Exibindo {{ meta.total }} produto(s)
                </h4>
              </div>
              <div class="col-6 ecomhny-topbar-ordering"></div>
            </div>
            <div class="ecom-products-grids row">
              <template v-for="produto in produtos" :key="produto.id">
                <div class="col-lg-4 col-6 product-incfhny mt-4">
                  <div class="product-grid2 shopv">
                    <div class="product-image2">
                      <router-link
                        :to="{
                          name: 'app.produto.visualizacao',
                          params: { id: produto.id },
                        }"
                      >
                        <img
                          class="pic-1 img-fluid radius-image"
                          :src="produto.imagem_1?`${publicUrl}${produto.imagem_1}`:'/assets/images/sem-imagem.jpg'"
                        />
                        <img
                          class="pic-2 img-fluid radius-image"
                          :src="produto.imagem_1?`${publicUrl}${produto.imagem_1}`:'/assets/images/sem-imagem.jpg'"
                        />
                      </router-link>
                      <!-- <ul class="social">
                        <li>
                          <router-link
                            :to="{
                              name: 'app.produto.visualizacao',
                              params: { id: produto.id },
                            }"
                          >
                            <span class="fa fa-eye"></span>
                          </router-link>
                          <a href="products.html#" data-tip="Quick View"
                            ><span class="fa fa-eye"></span
                          ></a>
                        </li>

                        <li>
                          <a href="products.html" data-tip="Add to Cart"
                            ><span class="fa fa-shopping-bag"></span
                          ></a>
                        </li>
                      </ul> -->
                      <div class="shopv single-item">
                        <!-- <form action="products.html#" method="post">
                          <input type="hidden" name="cmd" value="_cart" />
                          <input type="hidden" name="add" value="1" />
                          <input
                            type="hidden"
                            name="shopv_item"
                            value="Checkered Casual Shirt"
                          />
                          <input type="hidden" name="amount" value="899.99" />
                          <button
                            type="submit"
                            class="shopv-cart pshopv-cart add-to-cart"
                          >
                            Visualizar
                          </button>
                        </form> -->
                        <router-link
                          class="shopv-cart pshopv-cart add-to-cart"
                          :to="{
                            name: 'app.produto.visualizacao',
                            params: { id: produto.id },
                          }"
                        >
                          Visualizar
                        </router-link>
                      </div>
                    </div>
                    <div class="product-content">
                      <h3 class="title">
                        <router-link
                          :to="{
                            name: 'app.produto.visualizacao',
                            params: { id: produto.id },
                          }"
                        >
                          {{ produto.descricao }}
                          {{ produto.veiculo }} 
                        </router-link>
                      </h3>
                      <!-- <span class="price">
                        <del>R$ 975,00</del>
                        R$ 899,99
                      </span> -->
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="pagination">
              <ul>
                <template v-for="n in meta.links" :key="n">
                  <li>                    
                    <a v-if="n.label > 0"
                      :class="{ 'active': n.label == meta.current_page }"
                      @click="getProdutos(n.label)"
                      href="javascript:void(0);"
                      >{{ n.label }}</a>
                  </li>
                </template>

                <!-- <li class="prev">
                  <a href="products.html#page-number"
                    ><span class="fa fa-angle-double-left"></span
                  ></a>
                </li>
                <li>
                  <a href="products.html#page-number" class="active">1</a>
                </li>
                <li><a href="products.html#page-number">2</a></li>
                <li><a href="products.html#page-number">3</a></li>

                <li class="next">
                  <a href="products.html#page-number"
                    ><span class="fa fa-angle-double-right"></span
                  ></a>
                </li> -->
              </ul>
            </div>
          </div>
          <div v-else>
            ...
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- //products-->
  <div style="margin: 8px auto; display: block; text-align: center">
    <!---728x90--->
  </div>
</template>

<script>
// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

import { onMounted, onBeforeMount, reactive } from "vue";
import { useRoute } from "vue-router";
import useSite from "@/composables/Site";

export default {
  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  setup() {
    const { meta, paramsSearch, produtos, produtosResumo, getProdutos } = useSite();

    const search_ = reactive({
      qs: ''
    });

    const checkPesquisa = () => {
      paramsSearch.value = useRoute().query;
      search_.qs = useRoute().query.search||'';
    };

    onMounted(function () {

      checkPesquisa();
      // getProdutos();
      
    });

    onBeforeMount(async ()=>{

      checkPesquisa();
      await getProdutos();

      
    });

    

    return {
      paramsSearch,
      meta,
      produtos,
      produtosResumo,
      getProdutos,

      publicUrl: `${process.env.VUE_APP_URL_PUBLIC+'/produto/'}`,
      search_
    };
  },
};
</script>

<style></style>
